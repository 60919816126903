import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import uiuxDesign from "../assets/img/uiuxdesign.png";
import uiuxSolitions from "../assets/img/uiux-solitions.png"
import uiuxSolitions2 from "../assets/img/uiux-solitions2.png"
import tcddDev from "../assets/img/tcdd-dev.png"
import lines from "../assets/img/liness.png"
import popupsmartDev from "../assets/img/popupsmart-dev.png"
import circle from "../assets/img/circle.png"
import lavarel from "../assets/img/lavarel.png"
import vue from "../assets/img/vue-core.png"
import Gallery from "../components/gallery/gallery"
import balance from "../assets/img/balance-time-money.png"
import achievement from "../assets/img/achievement-female.png"
import videoSearch from "../assets/img/video-search.png"
import cv from "../assets/img/cv-curriculum.png"
import maleMan from "../assets/img/male-man.png"
import business from "../assets/img/business-idea.png"
import work from "../assets/img/work-preformance.png"
import Teams from "../components/teams/design-team"
import SSS from "../components/sss/ui-sss"
import checkModal from "../assets/img/check-modal.png";
import Companys from "../components/seo-companys"


class uiUxDesign extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Success!";
            }
            else {
                document.querySelector('.status').innerHTML = "Error";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="UI/UX Design" description="We shape the path to interaction and design high-conversion interfaces to give your target audience a good user experience on the website." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1 >UI/UX<br />Design</h1>
                            <span className="red-head">SERVICES</span>
                            <p style={{ marginTop: 25 }}>We shape the path to interaction and design high-conversion interfaces to give your target audience a good user experience on the website.</p>
                            <input type="text" className="pink-input" placeholder="Enter your e-mail address" value={this.state.value} onChange={this.handleChange} />
                            <button className="red-button" id="uiuxButton" onClick={(e) => this.showModal()}>Get your free UX Audit</button>
                        </div>
                        <div className="col-md-6" style={{ paddingLeft: 15, marginTop: 40 }}><img alt="alt text" src={uiuxDesign} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Fill the form</h2>
                            <p className="form-desc">Enter your information in the fields to get information, and we will get back to you as soon as possible.</p>
                            <div className="check-modal_container">
                                <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required  placeholder="Enter your website." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Enter your name" />
                                    <input type="email" id="email" required  name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="Enter yor email" />
                                    <button  >SEND</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uiUx container" style={{marginTop:50}}>
                    <div className="seo-referances_company py60">
                        {/* <div className="companyz_left">
                            <p><span>+%28</span></p>
                            <p style={{ margin: '10px 0px' }}>Reklam etkileşim oranı Pakmaya çikolata kampanyası</p>
                            <Link>Çalışma detaylarına göz atın.</Link>
                            <img alt="alt text" style={{ height: 60, margin: '20px 0px' }} src={pakmaya} />
                        </div> */}
                        <Companys />
                    </div>

                </div>
                <div className="uiUx-solutions">
                    <h2>Our UI / UX Design Solutions.</h2>
                    <div className="uiUx-solutions-row py60">
                        <div className="uiUx-solutions-row_img col-md-6">
                            <img alt="alt text" id="mobil-scale" src={uiuxSolitions} />
                        </div>
                        <div className="uiUx-solutions-row_content col-md-6">
                            <h3>User Research,<br/> Schema Design,<br/> and Prototypes</h3>
                            <p>We want to get to know your users better. We are interested in how they will feel when they go on to your website. </p>
                            <p>In order to achieve that, we make research about your users, analyze the data, and based on that we design schemes of ideas and create their prototypes.</p></div>
                    </div>
                    <div className="uiUx-solutions-row">
                        <div className="uiUx-solutions-row_img2 col-md-6">
                            <img alt="alt text" src={uiuxSolitions2} />
                        </div>
                        <div className="uiUx-solutions-row_content col-md-6">
                            <h3>User Interface (UI) Design</h3>
                            <p>As a result of our UX design process, we realize the design elements of the user interface color, hierarchy, visual, typography, composition and create a website that meets your marketing goals.</p>
                        </div>
                    </div>
                </div>

                <div className="development-studio container py60">
                    <h2>Some of our projects from<br/> our UI / UX design studio.</h2>
                    <div className="tcdd-dev">
                        <img alt="alt text" src={tcddDev}></img>
                        <img alt="alt text" className="lines" src={lines}></img>
                        <h3>TCDD Transportation <br /> Website Development & UX Design</h3>
                        <p>We have developed a practical, functional and user-oriented website where TCDD passengers can easily make their travel plans.</p>
                        <img alt="alt text" className="lavarel" src={lavarel}></img>
                    </div>
                    <div className="popupsmart-dev py60">
                        <div className="row">
                            <div className="col-md-5">
                                <h3>Popupsmart<br />
                                    Website & Mobil <br />
                                    Application Development</h3>
                                <p>We have developed a website and app that makes it possible to create popups with practical steps for the conversion optimization toolkit Popupsmart.</p>
                                <p>Popupsmart customers can effortlessly integrate popups into their websites and optimize their conversions.</p>
                                <Link className="content-link">Check out the details.</Link><br />
                                <img alt="alt text" className="vuejs" src={vue} />
                            </div>
                            <div className="col-md-6">
                                <img alt="alt text" src={popupsmartDev} />

                                <img alt="alt text" className="circle" src={circle} />
                            </div>
                        </div>
                    </div>
                </div>

                <Gallery name="web" />

                <div className="container manage-google manage-uiUx">
                    <h2 style={{ marginTop: 20 }}>Why you should choose us<br/> for your UI / UX design?</h2>
                    <p className="manage-google_p">	We are making user experience-oriented interface designs for your website. <br/> Within this interface design, your users will have the best experience and can find what they are looking for without any problems. </p>
                    <div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={balance} />
                            <div>
                                <h3>Working with Advanced Experts</h3>
                                <p>Our UI / UX design projects are managed by a professional team of experts, not trainees or less experienced people.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={achievement} />
                            <div>
                                <h3>Creating Value</h3>
                                <p>We focus on effective work to increase your return on investment and continuously measure the KPIs we have set together.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={videoSearch} />
                            <div>
                                <h3>Effective Communication Methods </h3>
                                <p>We regularly meet with you to review the performance of UI / UX designs and discuss new ideas.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={cv} />
                            <div>
                                <h3>Special Report For You</h3>
                                <p>We regularly report the results of all your performance indicators and the most important statistics for your brand.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={maleMan} />
                            <div>
                                <h3>Brand Image Priority</h3>
                                <p>Your website and app create the first impression of your brand. For this reason, we consider both the image of your brand and the expectations of the users in our designs.</p>
                            </div>
                        </div>
                        <div className="col-md-6 manage-google_item">
                            <img alt="alt text" src={business} />
                            <div>
                                <h3>Affordable Pricing</h3>
                                <p>While achieving effective and successful e-mail marketing campaigns, you won't have to pay high fees.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="onboarding container py60" style={{ marginTop: 40, marginBottom: 40 }}>
                    <div className="col-md-4">
                        <img alt="alt text" src={work} />
                        <h2>Our operation<br/> process</h2>
                        <p>Check out how we maintain our UI / UX design process for our brands.</p>
                    </div>
                    <div className="col-md-8">
                        <div className="hiring-tabs">
                            <input type="checkbox" name="onboarding" id="tab6" />
                            <input type="checkbox" name="onboarding" id="tab7" />
                            <input type="checkbox" name="onboarding" id="tab8" />
                            <input type="checkbox" name="onboarding" id="tab9" />
                            <input type="checkbox" name="onboarding" id="tab10" />
                            <input type="checkbox" name="onboarding" id="tab11" />

                            <div className="tab" id="tab6sec">
                                <label htmlFor="tab6"> </label>
                                <h3> <span>1</span> A Discovery interview is held.</h3>
                                <p>- First of all, we conduct a meeting to get to know you and your brand better. In this meeting, we discuss the details about the sector you serve as well as your goals, competitive advantages, target audience, factors affecting your performance. </p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab7sec">
                                <label htmlFor="tab7"> </label>
                                <h3> <span>2</span>Existing websites and applications are examined..</h3>
                                <p>- We analyse your current performance and identify the missing points. We gather data that we would later use while creating our strategies.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab8sec">
                                <label htmlFor="tab8"> </label>
                                <h3> <span>3</span> Findings are spoken to move on to the next steps.</h3>
                                <p>- We organize a face-to-face meeting or video talk to present insights to you. We offer a detailed business plan and ROI calculations resulting from user research.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab9sec">
                                <label htmlFor="tab9"> </label>
                                <h3> <span>4</span> An initial strategy is created and action<br/> is taken.</h3>
                                <p>- We design and implement user-oriented interfaces by developing prototypes in line with the determined strategy.</p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab10sec">
                                <label htmlFor="tab10"> </label>
                                <h3> <span>5</span>Optimizations and detailed monthly <br/>reports are maintained.</h3>
                                <p>- We meet with you weekly to review performance and present new ideas. </p>
                                <div className="plus"></div>
                            </div>
                            <div className="tab" id="tab11sec">
                                <label htmlFor="tab11"> </label>
                                <h3> <span>6</span> Strategic planning sessions for the next<br/> quarter are held every three months. </h3>
                                <p>- In each quarter, a meeting is held. In this meeting, ongoing optimizations and strategic plans are put on the table, a detailed analysis evaluation is made for the next period, and your budget is managed to offer the highest value.</p>
                                <div className="plus"></div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="team">
                    <h2 style={{ textAlign: 'center', marginBottom: 120 }}> Our UX / UI designers<br/> who can help you. </h2>
                    <Teams />
                </div>*/}
                <div className="hiring" style={{ marginTop: -80 }}>
                    <h2>Frequently Asked Questions</h2>
                    <SSS />
                </div>
            </Layout>
        )
    }
}
export default uiUxDesign
