import React from "react"



const SSS = () => (


    <div className="hiring-tabs">
        <input type="checkbox" name="dropdown" id="tab1" />
        <input type="checkbox" name="dropdown" id="tab2" />
        <input type="checkbox" name="dropdown" id="tab3" />
        <input type="checkbox" name="dropdown" id="tab4" />
        <input type="checkbox" name="dropdown" id="tab5" />

        <div className="tab" id="tab1sec">
            <label htmlFor="tab1"> </label>
            <h3>What technologies do you use for UI / UX designs?</h3>
            <p>We use the latest version Vue.js, ASP.NET Core, Adobe products to improve customer experience in your website and in your applications. </p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab2sec">
            <label htmlFor="tab2"> </label>
            <h3>Do UI / UX designs affect SEO and advertising work?</h3>
            <p>Yes, a good UI / UX design positively affects all your digital activities. Because Google values websites with high user experience and makes them more prominent.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab3sec">
            <label htmlFor="tab3"> </label>
            <h3>What is the difference between UI and UX?</h3>
            <p>UI stands for user interface and deals with the design of your website and application interface that users can access. UX means user experience and is about understanding the expectations of users.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
        <div className="tab" id="tab4sec">
            <label htmlFor="tab4"> </label>
            <h3>Can UI / UX design be done on the existing website?</h3>
            <p>Of course. Using your existing infrastructure, interfaces can be designed to suit your users’ expectations. </p>
            <svg xmlns="http://www.w3.org/2000/svg" width="6.099" height="9.196" viewBox="0 0 8.099 14.196">
                <path id="Union_21" data-name="Union 21" d="M6.1,6.1,0,12.2ZM0,0,6.1,6.1Z" transform="translate(1 1)" fill="none" stroke="#a7a9bc" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            </svg>
        </div>
    
    </div>
)

export default SSS
